exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-4041-js": () => import("./../../../src/pages/4041.js" /* webpackChunkName: "component---src-pages-4041-js" */),
  "component---src-pages-catalogo-js": () => import("./../../../src/pages/catalogo.js" /* webpackChunkName: "component---src-pages-catalogo-js" */),
  "component---src-pages-conocenos-js": () => import("./../../../src/pages/conocenos.js" /* webpackChunkName: "component---src-pages-conocenos-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-enviado-js": () => import("./../../../src/pages/enviado.js" /* webpackChunkName: "component---src-pages-enviado-js" */),
  "component---src-pages-index-11-js": () => import("./../../../src/pages/index11.js" /* webpackChunkName: "component---src-pages-index-11-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

